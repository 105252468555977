import React, { Component } from 'react';
import {Link,Navigate, Route  } from "react-router-dom";
import NABILogo from "../../assets/images/brand/NABI-Logo.jpg";
import startupindia from "../../assets/images/brand/startup-india-4.jpeg";
import logo from "../../assets/images/brand/logo.png";
import {getUserName,isUserLoggedIn,removeAuth} from  '../../auth';
import { Redirect   } from  'react-router-dom';
import { useHistory } from "react-router";

class MainHeader extends Component {


    childMethod() {
        
        this.setState({
            userLoggedIn:true
        });
    }

    constructor(props){
        super(props);
        this.state = {
            addActiveClass: "submneu-open",
            userLoggedIn:isUserLoggedIn()
        }
    }
    

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }
    openMenu = () => this.setState({ isOpen: true });
    openMenu = (event) => {
        document.body.classList.add('open-menu');
        document.body.classList.remove('close-menu');
        this.setState({ showModal: true });
    }

    closeMenu = () => this.setState({ isOpen: true });
    closeMenu = (event) => {
        document.body.classList.add('close-menu');
        document.body.classList.remove('open-menu');
        this.setState({ showModal: true });
    }

    Logout=()=>
    {
        removeAuth();
        this.setState({
            userLoggedIn: false
        });

        
    }
          

   
    render() {
        return (

            <>    
   
            <header className="header">
                <div className="container-fluid">
                    <nav className="navbar navbar-expand-lg header-nav nav-transparent">
                        <div className="brand-logo">                                    
                            <Link to="/"  className="logo-wrapper waves-effect">
                                <img alt="Vendor service" className="img-fluid" src={logo}/>
                            </Link>
                            {/* <div className="paward-by-logo">
                                <img alt="Vendor service" className="img-fluid" src={NABILogo}/>
                                <img alt="Vendor service" className="img-fluid startup-india-img" src={startupindia}/>
                            </div>    */}
                        </div>   
                        
                        <div className="nav-bar">
                            <div className="nav-bg home-three-nav">                           
                                <div className="navbar-header">
                                    <a id="mobile_btn"  onClick={this.openMenu}>	
                                        <span className="bar-icon blue-bar">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </span>
                                    </a>                               
                                    {/* <a href="javaScript:void(0)">
                                        <img alt="VET" className="img-fluid" src={logo}/>
                                    </Link> */}
                                </div>
                                <div className="main-menu-wrapper">
                                    <div className="menu-header">
                                        <Link to="/"  className="menu-logo">
                                            <img alt="Vendor service" className="img-fluid" src={NABILogo}/>
                                             <img alt="Vendor service" className="img-fluid startup-india-img" src={startupindia}/>
                                        </Link>
                                        <a id="menu_close" className="menu-close"  onClick={this.closeMenu}>	<i className="fas fa-times"></i>
                                        </a>
                                    </div>
                                    <ul className="main-nav black-font" >
                                        <li className="has-submenu"><Link to="/">Home</Link> </li>
                                        <li className="has-submenu"><Link to="/AboutUs">About  </Link></li>
                                        
                                        {/* <li className="has-submenu" onClick={() => this.setState({addActiveClass: !this.state.addActiveClass})}>
                                            <Link to="/">Doctors <i className="fas fa-chevron-down"></i></Link>
                                            {this.state.addActiveClass && <ul className="submenu">											
                                                <li>
                                                <Link to="/DoctorSearch">Doctor Search</Link></li>
                                                <li><Link to="/">Consultation Charges</Link> </li>
                                            </ul>}

                                        </li> */}
                                        <li className="has-submenu" ><Link to="/DoctorSearch?q=&location=&locationid=" >Doctor </Link></li>                                            
                                        <li className="has-submenu" ><Link to="/vendorsearch" >Service Provider</Link></li>                                            
                                      
                                        <li className="has-submenu"><Link to="/ContactUs">Contact Us  </Link></li>
                                          {/* <li className="has-submenu" ><Link to="/shop" >Shop</Link></li>                                             */}
                                        {/* <li className="has-submenu"><Link to="/">Blog</Link></li> */}
                                                                                 
                                        {
                                        
                                        this.state.userLoggedIn==true?

                                            <li className="has-submenu" onClick={() => this.setState({addActiveClass: !this.state.addActiveClass})}>
                                            <a>   Welcome { getUserName()  } <i className="fas fa-chevron-down"></i></a>
                                            {this.state.addActiveClass && <ul className="submenu">											
                                                <li>
                                                   <Link to="/profile">My Dashboard</Link> 
                                                </li>
                                                <li>
                                                   <Link to="/MyAppointments">My Appointements</Link> 
                                                </li>
                                                <li>
                                                   <Link to="/ChangePassword">Change Password</Link> 
                                                </li>
                                                <li>
                                                    {/* <a hred="JavaScript:void(0)" onClick={(e)=>this.Logout()}>Logout</a> */}
                                                    <Link to={"/"} onClick={(e)=>this.Logout()} >Logout</Link>
                                                </li>                                                
                                            </ul>}
                 
                                            </li>
                                            :<ul className="nav header-navbar-rht right-menu">
                                                <li className="nav-item">
                                                    <Link to="/login" className="nav-link btn-two rounded-pill">                                                     
                                                        <i className="fa fa-user mr-1" aria-hidden="true"></i>  Sign In / Sign Up
                                                    </Link>
                                                </li>
                                                </ul>
                                            
                                            }
                                    </ul>
                                    <ul className="nav header-navbar-rht right-menu">
                                                                                
                                        {/* <li className="has-submenu">
                                            <Link to="/">
                                                <div id="ex4">
                                                    <span className="p1 fa-stack fa-2x has-badge position-relative">
                                                        <span className="cart-count">4</span>
                                                        <i className="p3 fa fa-shopping-cart fa-stack-1x "></i>
                                                    </span>
                                                </div>
                                            </Link>
                                        </li> */}
                                    </ul>
                                </div>                             
                            </div>
                       </div>
                
                    </nav>
            
                </div>            
            </header>    
    
           
            </>
        );
    }
}

export default MainHeader;